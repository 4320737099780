<template>
  <ion-page>
    <LoggedInNav
      :title="'Sobre Gramos'"
      :back="true"
      :backUrl="'/configuracion'"
    />

    <ion-content>
      <div class="centerCenter">
        <div class="sobreGramosContenedor">
          <div class="centerCenter" style="margin-top: 50px">
            <img
              src="../../../../public/assets/img/logo.svg"
              style="max-width: 40%"
            />
          </div>
          <div
            style="padding: 30px 30px 10px 30px; text-align: center"
            class="centerCenter"
          >
            <span
              style="
                line-height: 1.5;
                color: #545454;
                font-size: 14px;
                font-family: 'Avenir';
              "
            >
              GRAMoS es el Programa Desarrollado por Personas que Adelgazamos
            </span>
          </div>

          <div
            style="padding: 30px 30px 10px 30px; text-align: center"
            class="centerCenter"
          >
            <span
              style="
                line-height: 1.5;
                color: #545454;
                font-size: 14px;
                font-family: 'Avenir';
              "
            >
              En GRAMoS, queremos ayudarte a adelgazar. Sin importar tus
              preferencias alimenticias o el lugar en el que vives, ponemos a tu
              disposición todo un sistema para ayudarte a mejorar tu salud, tu
              calidad de vida y tu aspecto físico, en forma saludable, fácil y
              rápida. El Programa GRAMoS fue desarrollado con estricta supervisión
              médica por personas que adelgazamos y a las que nos apasiona
              contribuir a cambiar para bien la Vida de las Personas
            </span>
          </div>
        </div>
      </div>
      
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonPage, IonContent } from "@ionic/vue";
import LoggedInNav from "../../../components/LoggedInNav.vue";
export default defineComponent({
  name: "SobreGramos",
  components: {
    IonPage,
    IonContent,
    LoggedInNav,
  },
  data() {
    return {};
  },
  methods: {},
});
</script>

<style scoped>
.sobreGramosContenedor {
    width: 100%;
  }
  @media (min-width: 720px) {
    .sobreGramosContenedor {
      width: 720px;
    }
  }
</style>

